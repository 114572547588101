<script>
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      emps: [],
      limit: 20,
      page: 1,
      allSelected: false,
      userIds: [],
      workDays: [{}],
      days: [],
      emp_workDays: [],
      selectedEmpDays: [],
    };
  },
  methods: {
    deleteEmpWorkday(emp) {
      console.log(emp);
      const { id } = emp?.workday;
      this.http
        .post("emps/delete-workdays", {
          emp_id: emp?.emp_id,
          workdays_id: emp?.workday.id,
        })
        .then((res) => {
          if (res.data) {
            this.selectedEmpDays.splice(id, 1);
          }
        });
    },
    updateEmpWorkDays() {},
    editEmpWorkDays(emp) {
      this.http
        .post("emps/get-workdays", {
          emp_id: emp.id,
        })
        .then((res) => {
          // console.log(res.data);
          this.selectedEmpDays = res.data;
        });
    },
    getWorkDays(emp) {
      this.http
        .post("emps/get-workdays", {
          emp_id: emp.id,
        })
        .then((res) => {
          this.emp_workDays = res.data;
          console.log(this.emp_workDays);
        });
    },
    addWorkDays() {
      if (this.userIds.length <= 0) {
        var data = {
          title: "popups.usersErrorTitle",
          msg: "popups.err_select_emp",
        };
        this.popup.modal(data);
        return;
      }
      this.http
        .post("emps/workdays", {
          emp_id: this.userIds,
          workdays: this.workDays,
        })
        .then((res) => {
          console.log(res);
        });
    },
    selectAll() {
      this.allSelected = !this.allSelected;
      this.userIds = [];
      if (this.allSelected) {
        for (let i = 0; i < this.emps.length; i++) {
          this.userIds.push(this.emps[i].id);
        }
      } else if (!this.allSelected) {
        this.userIds = [];
      }
    },
    select() {
      this.allSelected = false;
    },
    getEmployees(page) {
      this.http
        .post("emps/branch/paginate", {
          limit: this.limit,
          page,
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.emps = res.data;
        });
    },
    getWeekDays() {
      this.http.get("emps/days").then((res) => {
        this.days = res.data;
      });
    },
  },
  created() {
    this.getEmployees(1);
    this.getWeekDays();
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
      :title="$t('menu.menuitems.hr.subItems.emps-work-day')"
    />
    <div class="row">
      <div class="card">
        <div class="card-body">
          <div class="row mb-4">
            <div class="col">
              <button
                type="button"
                class="btn btn-light float-end"
                data-bs-toggle="modal"
                data-bs-target="#addWordDays"
              >
                <span
                  class="bx bxs-plus-circle float-end fa-2x"
                  style="color: #2a3042 !important"
                ></span>
              </button>
            </div>
          </div>
          <div class="table-responsive">
            <table
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
              class="table table-striped table-hover mb-0"
            >
              <thead>
                <tr
                  class="text-light"
                  style="background-color: #2a3042 !important"
                >
                  <th scope="col">
                    <input
                      v-model="allSelected"
                      @click="selectAll()"
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                  </th>
                  <th scope="col">#</th>
                  <th scope="col">{{ $t("emps.code") }}</th>
                  <th scope="col">{{ $t("emps.name") }}</th>
                  <th scope="col">{{ $t("emps.operations") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr class="" v-for="(emp, index) in emps" :key="emp">
                  <td>
                    <input
                      @change="select()"
                      v-model="userIds"
                      name="emp"
                      class="form-check-input"
                      type="checkbox"
                      id="checkbox"
                      :value="emp.id"
                    />
                  </td>
                  <td>{{ index + 1 }}</td>
                  <td>{{ emp.code }}</td>
                  <td>{{ emp.name }}</td>
                  <td class="d-flex">
                    <button
                      @click="getWorkDays(emp)"
                      style="margin-inline-end: 8px"
                      class="btn btn-info"
                      data-bs-toggle="modal"
                      data-bs-target="#wordDays"
                    >
                      <i class="bx bx-show"></i>
                    </button>
                    <button
                      data-bs-toggle="modal"
                      data-bs-target="#editWordDays"
                      @click="editEmpWorkDays(emp)"
                      class="btn btn-primary"
                    >
                      {{ $t("popups.edit") }}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </Layout>

  <!--Start Add Work Days Modal-->
  <div
    class="modal fade"
    id="addWordDays"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <form @submit.prevent="addWorkDays()">
          <div class="modal-header" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ $t("emps.add-work-day") }}
            </h5>
            <button
              style="margin: 0"
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="col d-flex justify-content-end">
              <button
                type="button"
                @click="workDays.push({})"
                class="btn float-end"
              >
                <span
                  class="bx bxs-plus-circle float-end fa-2x"
                  style="color: #2a3042 !important"
                ></span>
              </button>
            </div>
            <div v-for="day in workDays" :key="day">
              <label for="salary_item">{{ $t("emps.work-day") }}</label>
              <select
                required
                v-model="day.id"
                class="form-select mb-2"
                id="salary_item"
              >
                <option v-for="d in days" :key="d" :value="d.id">
                  {{ d.day }}
                </option>
              </select>
            </div>
          </div>
          <div class="modal-footer" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
            <button
              data-bs-dismiss="modal"
              type="submit"
              class="btn btn-primary"
            >
              {{ $t("popups.add") }}
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              {{ $t("popups.cancel") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!--End Add Work Days Modal-->

  <!-- start edit work days modal -->
  <div
    class="modal fade"
    id="editWordDays"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("emps.edit") }}
          </h5>
          <button
            style="margin: 0"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <table class="table table-responsive">
            <thead>
              <tr class="text-center">
                <th scope="col">{{ $t("emps.work-day") }}</th>
                <th scope="col">{{ $t("emps.operations") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="text-center"
                v-for="day in selectedEmpDays"
                :key="day.id"
              >
                <td scope="col">{{ day?.workday?.day }}</td>
                <td scope="col">
                  <button @click="deleteEmpWorkday(day)" class="btn btn-danger">
                    {{ $t("emps.delete") }}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- <div class="col d-flex justify-content-end">
              <button
                type="button"
                @click="workDays.push({})"
                class="btn float-end"
              >
                <span
                  class="bx bxs-plus-circle float-end fa-2x"
                  style="color: #2a3042 !important"
                ></span>
              </button>
            </div>
            <div v-for="day in workDays" :key="day">
              <label for="salary_item">{{ $t("emps.work-day") }}</label>
              <select
                required
                v-model="day.id"
                class="form-select mb-2"
                id="salary_item"
              >
                <option v-for="d in days" :key="d" :value="d.id">
                  {{ d.day }}
                </option>
              </select>
            </div> -->
        </div>
      </div>
    </div>
  </div>
  <!-- end edit work days modal -->

  <!--Start Work Days-->
  <div
    class="modal fade"
    id="wordDays"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("emps.work-day") }}
          </h5>
          <button
            style="margin: 0"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <span v-for="day in emp_workDays" :key="day">
            <button
              style="font-size: 15px"
              class="btn btn-light ms-2 mt-2 rounded-pill"
            >
              {{ day.workday?.day }}
            </button></span
          >
        </div>
      </div>
    </div>
  </div>
  <!--End Work Days-->
  <div>
    <!--   Apps  Pagination     -->
    <ul class="pagination pagination-rounded justify-content-center mb-2">
      <li class="page-item" :class="{ disabled: page == 1 }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page - 1)"
          aria-label="Previous"
        >
          <i class="mdi mdi-chevron-left"></i>
        </a>
      </li>
      <li
        class="page-item"
        :class="{ active: p == page }"
        v-for="p in tot_pages"
        :key="p"
      >
        <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
          p
        }}</a>
      </li>

      <li class="page-item" :class="{ disabled: page == tot_pages }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page + 1)"
          aria-label="Next"
        >
          <i class="mdi mdi-chevron-right"></i>
        </a>
      </li>
    </ul>
  </div>
</template>
<style scoped></style>
